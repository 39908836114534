@import '../common/variables.scss';

.selectionBg {
  @include absoluteFullscreen;
  z-index: 0;
}

.selectionArea {
  @include absoluteFullscreen;

  &.shift {
    cursor: crosshair !important;
  }
}

.selection {
  @include absoluteFullscreen;
  pointer-events: none;
  z-index: 200;
}

.selectionBox {
  stroke-width: 2px;
  stroke: yellow;
  fill: transparent;
}
