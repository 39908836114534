@import '../common/variables.scss';

.table {
  @include fullscreen;
  background-color: peachpuff;
  background-size: cover;
  position: relative;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}
