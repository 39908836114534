@import '../common/variables.scss';

.count {
  pointer-events: none;
  z-index: 300;
  position: absolute;
  font-size: 50px;
  margin: 10px;
  color: yellow;
  font-family: 'Luckiest Guy';
  text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
}
