@import '../common/variables.scss';

$handWidth: 240px;
$handHeight: 90px;

.hand {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 400;
  pointer-events: none;
}

.dropTarget {
  height: $handHeight;
  width: $handWidth;
  position: absolute;
  bottom: 0;
}

.container {
  height: $handHeight;
  box-sizing: border-box;
  width: 100%;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 6px dashed lightsalmon;
  border-bottom: 0;
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 0.2s;

  &.intersecting {
    opacity: 1;
    background-color: transparentize(lightsalmon, 0.8);
  }
}

.label {
  font-size: 25px;
  color: lightsalmon;
  font-family: 'Luckiest Guy';
}
