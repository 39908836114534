@import '../common/variables.scss';

.home {
  background-color: peachpuff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  .logo {
    height: 500px;
    z-index: 10;
    user-select: none;
    pointer-events: none;
    margin-bottom: 40px;
  }

  .actions {
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 500px;
  }

  .button {
    border: 3px solid #182026;
    margin: 20px 10px;
    flex-grow: 1;
    padding-bottom: 0;
    border-radius: 10px;
    font-size: 32px;
    padding-top: 12px;
  }

  .textField {
    border-radius: 10px;
    border: 3px solid #182026;
    margin: 20px 10px;
    padding-left: 20px;
    padding-right: 10px;

    &.name {
      margin: 10px 0;
    }

    .input {
      font-size: 32px;
      padding: 20px 0 10px;
    }
  }

  .error {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-align: center;
    font-size: 32px;
  }

  &.md {
    .logo {
      height: 400px;
      margin-bottom: 30px;
    }
    .actions {
      width: 400px;
    }

    .button {
      font-size: 24px;
      padding-top: 8px;
    }

    .textField .input {
      font-size: 24px;
      padding: 14px 0 8px;
    }

    .error {
      font-size: 24px;
    }
  }

  &.sm {
    .logo {
      height: 300px;
      margin-bottom: 20px;
    }
    .actions {
      width: 360px;
    }
  }

  &.xs {
    .logo {
      height: 220px;
      margin-bottom: 20px;
    }
    .actions {
      width: 320px;
    }

    .button {
      font-size: 20px;
      border-width: 2px;
      border-radius: 6px;
      padding-top: 6px;
    }

    .textField {
      border-radius: 6px;
      border-width: 2px;

      .input {
        font-size: 20px;
        padding: 12px 0 5px;
      }
    }

    .error {
      font-size: 20px;
      height: 30px;
    }
  }
}


.buttons {
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// .input {
  // position: absolute;
  // display: flex;
  // align-items: center;
  // font-size: 2vw;
  // text-align:center;

  // .textField {
  //   text-align: center;
  //   font-size: 2vw;
  //   padding: 1vw 2vw 0.5vw;
  //   border-radius: 10px;
  //   outline: 0 !important;
  //   border: 0 !important;
  //   background-color: rgba(0,0,0,0.1);
  // }
// }
