@import '../common/variables.scss';

.container {
  display: flex;
  flex-wrap: wrap;

  .command {
    width: 200px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .keys {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .key {
    padding: 10px 50px 5px 10px;
    // border: 2px solid rgba(0, 0, 0, 0.87);
    background-color: rgba(0,0,0,0.1);
    border-radius: 5px;
    display: inline-block;
  }

  .plus {
    font-size: 24px;
    margin: 5px 10px 0;
  }

  .keyWord {
    font-size: 20px;
    margin-top: 5px;
  }

  .videoWrapper {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .video {
    width: 150px;
    height: 150px;
  }

  &.small {
    align-items: center;
    flex-direction: column;
  }
}
