@import '../common/variables.scss';

.name {
  background-color: peachpuff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  .label {
    text-align: center;
    font-size: 48px;
  }

  .textField {
    border-radius: 10px;
    border: 3px solid #182026;
    margin: 10px 0;
    padding-left: 20px;
    padding-right: 10px;

    .input {
      font-size: 32px;
      padding: 20px 0 10px;
    }
  }

  &.md {
    .label {
      font-size: 36px;
    }

    .textField .input {
      font-size: 24px;
      padding: 14px 0 8px;
    }
  }

  &.xs {
    .label {
      font-size: 28px;
    }

    .textField {
      border-radius: 6px;
      border-width: 2px;

      .input {
        font-size: 20px;
        padding: 12px 0 5px;
      }
    }
  }
}
