@import '../common/variables.scss';

.controlsLeft {
  z-index: 600;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 5px;

  .gameCode {
    text-transform: uppercase;
    font-size: 16px;
    opacity: 0.5;
  }
}

.controlsRight {
  z-index: 600;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;

  .button {
    display: flex;
    padding-top: 10px;
    margin-left: 10px;
    text-align: center;
    background-color: peachpuff;

    .buttonText {
      flex-grow: 1;
    }

    &.copyButton > span {
      min-width: 120px;
    }

    .copyIcon {
      margin-top: -5px;
    }
  }

  .iconButton {
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 0;
    background-color: peachpuff;
  }
}

.overlay {
  z-index: 700;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.75);
}


