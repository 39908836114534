@import '../common/variables.scss';

.playerNames {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  pointer-events: none;
}

.name {
  box-sizing: border-box;
  width: 120px;
  display: flex;
  justify-content: center;
  position: absolute;
  font-size: 18px;
  padding: 10px 10px 4px;
  margin: 0;
  border-radius: 30px;
  background-color: rgba(0,0,0,0.75);
  color: lightsalmon;
  font-family: 'Luckiest Guy';
  transition: opacity 0.5s;

  &.hidden {
    opacity: 0;
  }

  span {
    @include ellipsis;
  }
}
