@import '../common/variables.scss';

.card {
  position: absolute;
  width: $cardWidth;
  height: $cardHeight;
  border-radius: $cardRadius;
  cursor: grab;

  &.dragging {
    cursor: grabbing;
  }

  &.preventInteraction {
    pointer-events: none;
  }
}

.cardOuter {
  width: $cardWidth;
  height: $cardHeight;
  perspective: 1000px;
  background-color: transparent;
  padding: 1px;

  &.selected {
    border-radius: $cardRadius;
    box-shadow: 0 0 0 3px yellow;
  }
}

.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);

  &.faceUp {
    transform: rotateY(360deg);
  }
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;

  .highlighted {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: $cardWidth;
    height: $cardHeight;
    border-radius: $cardRadius;
    pointer-events: none;
    opacity: 0.15;
  }
}

.front {
  z-index: 2;
	transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}

.image {
  user-select: none;
  border-radius: $cardRadius;
  width: $cardWidth;
  height: $cardHeight;
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.25);
  transition: box-shadow 0.2s;

  &.dragging {
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.3);
  }
}
