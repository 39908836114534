@import '../common/variables.scss';

.container {
  position: absolute;
  z-index: 700;
  top: 50%;
  left: 50%;
  font-family: 'Luckiest Guy';
  background-color: peachpuff;
  padding: 20px 30px;
  width: 500px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
  border-radius: 10px;

  .closeButton {
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .title {
    text-align: center;
    font-size: 36px;
  }

  .content {
    margin-top: -10px;
  }

  &.small {
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: none;
    border-radius: 0;
    box-sizing: border-box;
  }
}
