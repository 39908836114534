$tableWidth: 100vmin;
$tableHeight: 100vmin;

$cardRadius: 0.05 * 12vmin;
$cardWidth: 12vmin;
$cardHeight: 1.4 * 12vmin;

// $px: 160px;
// $cardRadius: 0.05 * $px;
// $cardWidth: $px;
// $cardHeight: 1.4 * $px;

@mixin absoluteFill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin fullscreen {
  height: 100vh;
  width: 100vw;
}

@mixin absoluteFullscreen {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
